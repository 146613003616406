import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { DashboardViewDto } from '@/api/contracts/models'
import { useProjectApi } from '../api/useProjectApi'

export function useDashboardViewsQuery(projectId: Ref<string>) {
   const client = useProjectApi()

   const enabled = computed(() => !!projectId.value);

   return useQuery([QueryKeys.WallDashboardViews, projectId], () => client.listDashboardViews(projectId.value), { enabled: enabled, refetchOnWindowFocus: false, staleTime: Infinity})
}

export function useDashboardViewsQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useProjectApi()

   return queryClient.prefetchQuery<DashboardViewDto[]>(
      [QueryKeys.WallDashboardViews, projectId],
      () => client.listDashboardViews(projectId.value)
   )
}
